module.exports = {
   // General
   _blank: ' ',
   select_language: 'Select Language',
   select_item_records: 'Select Item Records',
   select: 'Select',
   administration: 'Administration',
   dark_mode: 'Dark mode',
   light_mode: 'Light mode',
   required: 'Required',
   total_fields: 'Total fields',
   filter: 'Filter',
   create: 'Create',
   edit: 'Edit',
   close: 'Close',
   save: 'Save',
   update: 'Update',
   register: 'Register',
   total_records: 'Total Records',
   type_to_search: 'Type to search',
   copyright_notes: 'All rights reserved',
   member_information: 'Member Information',
   download: 'Download',
   supported_file_types: 'Supported file types',
   max_file_size: 'Max file size',
   drop_zone_intro_message:
      'Please drag files here or click to browse for files',
   view: 'View',
   delete: 'Delete',
   delete_all_risks: 'Delete All Risks',
   approved_successfully: 'Approved successfully.',
   declined_successfully: 'Declined successfully.',
   successfully_reverted: 'Successfully reverted.',
   notify_invoice_success: 'Invoice has been successfully created.',
   confirm_failed_revert_policy: 'Failed to revert policy approval.',
   confirm_failed_revert_quote: 'Failed to revert quote approval.',
   confirm_failed_create_invoice_policy: 'Failed to create invoice.',
   confirm_failed_approve_policy: 'Failed to approve policy.',
   confirm_failed_approve_quote: 'Failed to approve quote.',
   confirm_failed_approve_docType: 'Failed to approve {docType}.',
   confirm_failed_revert_docType: 'Failed to revert {docType} approval.',
   confirm_failed_recalcuate_commission: 'Failed to recalculate commission.',
   confirm_approve_docType: 'Are you sure to approve this {docType}?',
   confirm_revert_docType: 'Confirm revert approval for {docType} {docNumber}?',
   warning: 'Warning',
   confirm_delete_all_risks:
      'Are you sure to <span className="text-danger"> Permanently Delete All </span> Risks ?',
   confirm_approve_policy: 'Are you sure to approve policy {policyNumber}?',
   confirm_revert_policy: 'Confirm revert approval for policy {policyNumber}?',
   confirm_create_invoice_policy:
      'Are you sure to create invoice for policy {policyNumber}?',
   confirm_recalcuate_commission_policy:
      'Confirm recalcuate commission for policy {policyNumber}?',
   confirm_approve_claim: 'Are you sure to approve claim {claimNumber}?',
   confirm_revert_claim: 'Are you sure to revert claim {claimNumber}?',
   decline_approve_claim: 'Are you sure to decline claim {claimNumber}?',

   update_reason_decline_claim: 'Update claim decline reason {claimNumber}',
   confirm_approve_quote: 'Are you sure to approve quote number {quoteNumber}?',
   confirm_revert_quote: 'Confirm revert approval for quotation {quoteNumber}?',
   confirm_create_new_ver_quote:
      'Are you sure to create new version from quotation {quoteNumber}?',
   confirm_revert_quote_to_pol:
      'Are you sure to convert quotatoin {quoteNumber} to policy?',
   confirm_approved_claim_with_no_premium_paid:
      'Premium for policy number {polNumber} is not yet collected. Proceed?',
   failed_to_revert_claim: 'Failed to revert Claim',
   failed_to_approve_claim: 'Failed to approve Claim',
   failed_to_decline_claim: 'Failed to decline Claim',
   confirm_to_delete_partner: 'Are you sure to delete partner {partnerCode}?',
   post: 'Post',
   import: 'Import',
   export: 'Export',
   dataadmin: 'Data Admin',
   login_type: 'Login type',
   reset_password: 'Reset Password',
   confirm_delete: 'Are you sure to delete?',
   confirm_release_app: 'Are you sure to release app?',
   yes: 'Yes',
   no: 'No',
   sucessfully_saved: 'Sucessfully saved',
   sucessfully_deleted: 'Sucessfully deleted',
   sucessfully_released: 'Successfully released',
   confirm_delete_record: 'Are you sure to delete this record?',
   confirm_void_claim: 'Are you sure to void this claim?',
   confirm_post_record: 'Are you sure to post this record?',
   confirm_update_duplicated_risk:
      'Duplicated risk information (first name, last name, date of birth) found with the following risks: {detail}',
   are_you_sure_to_proceed: 'Are you sure to proceed?',
   submit: 'Submit',
   work_request_submit_success:
      'Your work request {number} has been succesfully submitted.',
   show: 'Show',
   show_only_description_in_membership: 'Show only description in membership',
   add_item: 'Add Item',
   add_items: 'Add Items',
   select_items: 'Select Items',
   select_item: 'Select Item',
   supported_excel_column_clinic:
      'Supported Excel columns: Code(auto), Name, Category, Price, Description, Long description',
   supported_excel_column_claim_item:
      'Supported Excel columns: Code(auto), Name, Description, Long description, Category',
   supported_excel_column:
      'Supported Excel columns: Code(auto), Name, Description, Long description',
   supported_excel_column_model: 'Supported Excel columns: Make,Model',
   page_error_title: 'An error occurred',
   error_code: 'Error code',
   error_message: 'Error Message',
   go_back_home: 'Go back home',
   tenant: 'Tenant',
   tenants: 'Tenants',
   tenant_name: 'Tenant name',
   tenant_code: 'Tenant code',
   registered_date: 'Registered date',
   url: 'URL',
   update_decline_reason: 'Update Decline Reason',
   print_claim_registered: 'Print Claim Registered',
   current_decline_reason: 'Curerent Decline Reason',
   new_reason: 'New Reason',
   enter_description: 'Enter Description',
   create_api_key: 'Create Api Key',
   decline_reason_history: 'Decline Reason History',
   update_reason_successfully: 'Update Reason Successfully',
   disabled: 'Disabled',
   login_email: 'Login email',
   login: 'Login',
   enable_row_permission: 'Enable row permission',
   data_access_parties: 'Only these teams can access this data:',
   parties: 'Parties',
   party_name: 'Party Name',
   min_value: 'Mininum value',
   field_required: 'Field required',
   min_length: 'Mininum characters',
   max_length: 'Maximum characters',
   invalid_format: 'Invalid format',
   total_users: 'Total users',
   host_name: 'Host name',
   documents: 'Documents',
   logo: 'Logo',
   address: 'Address',
   position: 'Position',
   note: 'Note',
   topic: 'Topic',
   communication: 'Communication',

   // Dashboard
   document_library: 'Document Library',
   reg_clinic_claim: 'Register Claim for Clinic',
   product_by_class: 'Product by Class',
   weekly_premiums: 'Weekly Premiums',
   weekly_claims: 'Weekly Claims',
   reciepts: 'Receipts',
   create_work_req: 'Create Work Request',
   print_letter: 'Print Letter',
   print_claim_letters: 'Print Claim Letters',
   claim_decline_letter: 'Claim Decline Letter',
   //Accounting

   date: 'Date',
   doc: 'Doc',
   next_date: 'Next Date',
   grand_total: 'Grand Total',
   sub_total: 'Sub Total',
   ship_to_address: 'Ship to address',
   billing_address: 'Billing Address',
   address_line_1: 'Address Line 1',
   address_line_2: 'Address Line 2',
   tax: 'Tax',
   total: 'Total',
   exchange_rate: 'Exchange Rate',
   discount_in_percent: 'Discount %',
   discount: 'Discount',
   gross: 'Gross',
   price: 'Price',
   as_of_date: 'As of Date',
   quantity: 'Quantity',
   pay_amount: 'Pay Amount',
   min_premium: 'Min. Premium',
   comission_percent: 'Commission %',
   premium: 'Premium',
   original_premium: 'Original Premium',
   recalculate_premium: 'Recalculate Premium',
   amount: 'Amount',
   doc_amount: 'Doc Amount',
   due_amount: 'Due Amount',
   receipt_from: 'Receipt From',
   payment_method: 'Payment Method',
   ref_number: 'Reference Number',
   reference: 'Reference',
   payment_number: 'Payment Number',
   pay_method: 'Pay Method',
   currency: 'Currency',
   invoice_number: 'Invoice Number',
   total_paid: 'Total Paid',
   total_pay_amount: 'Total Pay Amount',
   receipt_number: 'Receipt Number',
   receipt_amount: 'Receipt Amount',
   ship_date: 'Ship Date',
   due_date: 'Due Date',
   location: 'Location',
   partner: 'Partner',
   account_number: 'Account Number',
   payee_name: 'Payee Name',
   // pay_method: 'Pay Method',
   ship_via: 'Ship via',
   tracking_number: 'Tracking Number',
   custom_condition_note: 'Custom Condition Note',
   transaction_amount: 'Transaction Amount',
   doc_date: 'Doc Date',
   amount_due: 'Amount Due',
   in_due: 'In Due',
   less_than_30days: '<30 Days',
   less_than_60days: '<60 Days',
   less_than_90days: '90 Days',
   over_90days: 'Over 90 Days',
   create_reciept: 'Create Receipt',
   revert_approve: 'Revert Approval',
   print_invoice: 'Print Invoice',
   print_receipt: 'Print Receipt',
   print: 'Print ',
   receipt_viewer: 'Receipt Viewer',
   invoice_viewer: 'Invoice Viewer',
   payment_amount: 'Payment Amount',
   print_bill: 'Print Bill',
   bill_number: 'Bill Number',
   create_payment: 'Create Payment',
   bill_viewer: 'Bill Viewer',
   grand_total_base: 'Grand Total (Base)',
   payment_viewer: 'Payment Viewer',
   foreign_currency: 'Foreign Currency',
   paid_date: 'Paid Date',
   receipt_history: 'Receipts History',
   payment_history: 'Payments History',
   reciept_amount: 'Receipt Amount',
   reciept_date: 'Receipt Date',
   payment_date: 'Payment Date',

   //Underwrite
   create_risk: 'Create Risk',
   create_risk_master: 'Create Risk Master',
   create_smi_master: 'Create Smi Master',
   create_cover_master: 'Create Cover Master',
   family_code: 'Family Code',
   member_code: 'Member Code',
   family_limit: 'Family Limit',
   risk_limit: 'Risk Limit',
   smi_limits: 'SMI Limits',
   smi_limit: 'SMI Limit',
   policy_number: 'Policy Number',
   total_premium: 'Total Premium',
   total_risk_discount: 'Total Risk Discount',
   policy_discount: 'Policy Discount',
   pay_date: 'Pay Date',
   pay_to: 'Pay To',
   commission: 'Commission',
   custom_condition: 'Custom Condition',
   family_head: 'Family Head',
   eff_date_from: 'Effective Date From',
   eff_date_to: 'Effective Date To',
   issue_date: 'Issue Date',
   member_search: 'Member Search',
   membership_card: 'Membership Card',
   print_membership_card: 'Print Membership Card',
   print_cards: 'Print Cards',
   my_profile: 'My Profile',
   expire_date: 'Expire Date',
   sum_insured: 'Sum Insured',
   member_name: 'Member Name',
   min_sum_insured: 'Min Sum Insured',
   max_sum_insured: 'Max Sum Insured',
   fixed_sum_insured: 'Fixed Sum Insured',
   min_amount: 'Min Amount',
   risk_info: 'Risk Info',
   certificate_viewer: 'Certificate Viewer',
   policy_schedule_viewer: 'Policy Schedule Viewer',
   policy_fleet_viewer: 'Policy Fleet Viewer',
   quote_schedule_viewer: 'Quotation Schedule Viewer',
   quote_fleet_viewer: 'Quotation Fleet Viewer',
   risk_information: 'Risk Information',
   smi_and_covers: 'Smi and Covers',
   risk_description: 'Risk Description',
   risk_to_delete: 'Select risks to delete',
   risk_to_add: 'Risk to add',
   smi_cover: 'Smi-Cover',
   risk_code: 'Risk Code',
   risk_codes: 'Risk Codes',
   producer: 'Producer',
   renewal: 'Renewal',
   referenced_quotation: 'Referenced Quotation',
   valid_days: 'Valid days',
   policy_limit: 'Policy Limit',
   policy_limit_description: 'Policy Limit Description',
   version: 'Version',
   unit: 'Unit',
   quote_number: 'Quote Number',
   sample_risk: 'Sample Risk',
   sample_risk_required: 'Sample Risk is required',
   excel_file: 'Excel file',
   edit_risk: 'Edit Risk',
   view_risk: 'View Risk',
   add_risk: 'Add Risk',
   original_risk: 'Original Risk',
   per_time: 'Per Time',
   limit_max_times: 'Max No. of times',
   limit_max_days: 'Max No. of days',
   limit_per_day: 'Limit per day',
   limit_per_claim: 'Limit per claim',
   max_limit: 'Max Limit',

   re_calculate_commission: 'Re-Calculate Commission',
   upload_risk: 'Upload Risk',
   upload_risks_to_delete: 'Upload risks to delete',
   download_template_upload: 'Download Template/Upload',
   download_template: 'Download Template',
   upload_eff_date: 'Upload Effective Date',
   founded_risks: 'Founded Riskes',
   upload_risks: 'Upload Risks',
   create_invoice: 'Create Invoice',
   print_certificate: 'Print Certificate',
   print_fleeting: 'Print Fleeting',
   print_quotation_schedule: 'Print Quotation Schedule',
   create_new_version: 'Create New Version',
   convert_to_policy: 'Convert To Policy',
   upload_policies: 'Upload Policies',
   discounts_loadings: 'Discounts/Loadings',
   discussion: 'Discussion',
   territory_limit: 'Territory Limit',
   subjectivity: 'Subjectivity',
   policy_wording: 'Policy Wording',
   ncd: 'NCD',
   discount_w_example: 'Discount/Loading (i.e. 5% or 50)',
   commune_sangkat: 'Commune/Sangkat',
   district_city: 'District/City',
   district: 'District',
   commune: 'Commune',
   province: 'Province',
   risk_premium: 'Risk Premium',
   risk_discount: 'Risk Discount',
   discount_loading: 'Discount/Loading',
   policies_admin: 'Policies Admin',
   view_quote: 'View Quote',
   view_policy: 'View Policy',
   year: 'Year',
   registration_number: 'Registration .No',
   endt_type: 'Endorsement Type',
   endorsements: 'Endorsements',
   endorsement: 'Endorsement',
   endorsement_number: 'Endorsement Number',
   details: 'Details',
   total_limit: 'Total Limit',
   create_endorsement: 'Create Endorsement',
   endorsement_type: 'Endorsement Type',
   endorsement_discount: 'Endorsement Discount',
   print_endorsement: 'Print Endorsement',
   view_endorsement: 'View Endorsement',
   print_quote: 'Print Quote',
   print_policy: 'Print Policy',
   print_fleeting_list: 'Print Fleeting List',
   print_quote_schedule: 'Print Quotation Schedule',
   print_policy_schedule: 'Print Policy Schedule',
   print_endorsement_schedule: 'Print Endorsement Schedule',
   print_operative_endorsement: 'Print Operative Endorsement',
   member_logins: 'Member Logins',
   generate_logins: 'Generate Logins',
   premium_calc_no_risk_info: 'Risk information not found.',
   link_risk: 'Link Risk',
   allow_claim_access: 'Allow Claim Access',
   allow_clinic_claim: 'Allow Claim at Clinic',
   nationality: 'Nationality',
   marital_status: 'Marital Status',
   height: 'Height',
   occupation: 'Occupation',
   digital_card: 'Digital Card',
   order_by: 'Order By',
   show_id_card: 'Show ID Card',
   show_cover_benefits: 'Show Cover Benefits & Limit',
   include_family: 'Include Family',
   discount_manual: 'Discount Manual',
   batch_delete_risk: 'Batch Delete Risks',
   delete_selected_risks: 'Delete Selected Risks',
   risk_premium_discount: 'Premium After Discount',
   admin_fee: 'Admin Fee',
   recalc_charge: 'Recalculate Charge',
   export_risks: 'Export Risks',
   // Claim
   check_out_date: 'Check Out Date',
   print_claim_decline_letter: 'Print Claim Decline Letter',
   claim_number: 'Claim Number',
   select_claim_records: 'Select claim records',
   claim_date: 'Claim Date',
   report: 'Report',
   report_viewer: 'Report Viewer',
   accident_date: 'Accident Date',
   ref_claim_number: 'Ref Claim Number',
   claim_handler: 'Claim Handler',
   estimate_amount: 'Estimate Amount',
   estimated: 'Estimated',
   total_amount: 'Total Amount',
   paid: 'Paid',
   paid_amount: 'Paid Amount',
   accumulated_amount: 'Accumulated Amount',
   create_new_item: 'Create New Item',
   outstanding: 'Outstanding',
   download_qr_claim_reg: 'Download QRCode',
   download_qrcode_claim: 'Download QRCode Claim',
   amount_exceed_oustanding_value: 'Amount Exceed Oustanding Value',
   pending_estimated_only: 'Pending Estimated Only',
   pending_approve_only: 'Pending Approve Only',
   pay_to_claims: 'Pay To Claims',
   claim_line: 'Claim Line',
   claim_details: 'Claim Details',
   dues: 'Dues',
   claim_dues: 'Claim Dues',
   created_by: 'Created By',
   cover_details: 'Cover Details',
   partner_due: 'Partner Due',
   add_partner_due: 'Add Partner Due',
   pay_to_company_account: 'Pay to company account instead of insured',
   pay_to_account: 'Pay to Account',
   paneled_clinic: 'Paneled Clinic',
   pay_deductible_now: 'Pay Deductible Now',
   insured_paid_deductible: 'Insured paid deductible',
   insured_pay_deductible: 'Insured pay deductible',
   insured_paid_the_bill: 'Insured paid the bill',
   insured_pay_the_bill: 'Insured pay the bill',
   amount_due_to_partner: 'Amount due to partner',
   amount_due_to_insured: 'Amount due to insured',
   insured_account: 'Insured Account',
   total_deductible: 'Total Deductible',
   preview: 'Preview',
   preview_new_tab: 'Preview New Tab',
   register_new_claim: 'Register New Claim',
   pending_approval_only: 'Pending Approval Only',
   outstanding_only: 'Outstanding Only',
   claim_payment_req: 'Claim Payment Request',
   claim_payment_reqs: 'Claim Payment Requests',
   default_payment_info: 'Default Payment Information',
   create_new_req: 'Create New Request',
   claiming_amount: 'Claiming Amount',
   agreed_amount: 'Agreed Amount',
   map_item_smi: 'Map Items to SMI',
   map_to_smi: 'Map to SMI',
   overlimit: 'Over Limit',
   limits: 'Limits',
   total_over_limit: 'Total Over Limit',
   view_limits: 'View Limits',
   maximum_limit: 'Maximum Limit',
   print_claim_offer: 'Print Claim Offer',
   claim_day_passed: 'Claim Day Passed',
   decline: 'Decline',
   approve_claim_item: 'Approve Claim Item',
   status_batch_update: 'Status Batch Update',
   batch_update_status: 'Batch Update Status',
   current_status: 'Current Status',
   update_status_to: 'Update status to',
   sent_notif_to_customers: 'Sent notification to customers',
   decline_reason: 'Decline Reason',
   claim_notif: 'Claim Notification',
   claim_note: 'Claim Note',
   send_notif: 'Send Notification',
   send_to_insureds: 'Send to Insureds',
   send_to_brokers: 'Send to Brokers',
   send_to_clients: 'Send to Clients',
   reload: 'Reload',
   reload_comfirm_message:
      'This will reset all previous status. Are you sure to reload?',
   voided: 'Voided',
   declined: 'Declined',
   send_to: 'Send to',
   send: 'Send',
   msg_status: 'Message Status',
   add_new: '+ Add New',
   insured_amount_due: 'Insured Amount Due',
   insured_special_note: 'Insured Special Notes',
   invalid_accident_date: 'Invalid Accident Date',
   update_insured: 'Update Insured',
   claim_sms: 'Claim SMS',
   free_tier: 'Free Tier',
   cost_per_sms: 'Cost per sms',
   claimprintlettertpl: 'Claim Print Letter Template',
   preview_claim_limits: 'Preview Claim Limits',
   claim_history: 'Claim History',
   //Setup

   insured: 'Insured',
   organisation_units: 'Organisational Units',
   insured_name: 'Insured Name',
   id: 'ID',
   number: 'Number',
   code: 'Code',
   level: 'Level',
   phone_number: 'Phone Number',
   first_name: 'First Name',
   last_name: 'Last Name',
   full_name: 'Full name',
   name_kh: 'Name Kh',
   type: 'Type',
   types: 'Types',
   phone1: 'Phone 1',
   write_check_to_name: 'Write check to name',
   class_name: 'Class Name',
   risk_type: 'Risk Type',
   class_code: 'Class Code',
   phone2: 'Phone 2',
   gender: 'Gender',
   list_of_values: 'List of values',
   is_corporate: 'Is corporate',
   local: 'Local',
   address_line1: 'Address Line 1',
   address_line2: 'Address Line 2',
   id_card_number: 'ID Card Number',
   id_card: 'ID Card',
   date_of_birth: 'Date of Birth',
   job_title: 'Job Title',
   email: 'Email',
   branch_name: 'Branch Name',
   risk_min_refund: 'Risk Minimum Refund',
   refund_per_rule: 'Refund Percentage Rule',
   phone: 'Phone',
   name: 'Name',
   local_name: 'Local Name',
   postal_code: 'Postal Code',
   parent: 'Parent',
   head: 'Head',
   make: 'Make',
   model_name: 'Model Name',
   employee: 'Employee',
   EMPLOYEE: 'Employee name',
   BROKER: 'Broker name',
   AGENT: 'Agent name',
   CUSTOMER: 'Customer name',
   VENDOR: 'Vendor name',
   REINSURER: 'ReInsurer name',
   BANK: 'Bank name',
   INSURED: 'Insured name',
   CLINIC: 'Clinic name',
   GARAGE: 'Garage name',
   broker: 'Broker',
   insurer: 'Insurer',
   list_type: 'List Type',
   bank: 'Bank',
   bank_name: 'Bank Name',
   beneficiary_bank: 'Beneficiary Bank',
   item_code: 'Item code',
   short_description: 'Short Description',
   customer: 'Customer',
   customer_type: 'Customer Type',
   customer_notes: 'Customer Notes',
   agent: 'Agent',
   charge: 'Charge',
   charges: 'Charges',
   apply: 'Apply',
   smis_and_covers: 'SMIs and Covers',
   param_type: 'Param Type',
   is_list: 'Is List',
   values: 'Values',
   parameters: 'Parameters',
   itemname: 'Item Name',
   groups: 'Groups',
   vatin: 'VATIN',
   vat_rate: 'VAT Rate',
   tax_rate: 'Tax %',
   group_name: 'Group Name',
   premium_rate: 'Premium Rate (i.e 15 or 15%)',
   group: 'Group',
   description: 'Description',
   long_description: 'Long Description',
   company_name: 'Company name',
   contact: 'Contact',
   contact_list: 'Contact List',
   contact_name: 'Contact Name',
   contact_email: 'Contact Email',
   contact_phone1: 'Contact Phone 1',
   contact_phone2: 'Contact Phone 2',
   contact_info: 'Contact Information',
   update_contact: 'Update Contact',
   beneficiary_co_name: 'Beneficiary Co-Name',
   permissions: 'Permissions',
   item_name: 'Item name',
   list: 'List',
   cover: 'Cover',
   area: 'Area',
   holiday: 'Holiday',
   holidays: 'Holidays',
   department: 'Department',
   approve: 'Approve',
   approved_only: 'Approved Only',
   approve_all: 'Approve All',
   total_approved_amount: 'Total Approved Amount',
   total_bill_amount: 'Total Bill Amount',
   approved_amount: 'Approved Amount',
   approved_reason: 'Approved Reason',
   revertapprove: 'Revert Approve',
   claim: 'Claim',
   min_year: 'Min. Year',
   max_year: 'Max. Year',
   min_cc: 'Min. CC',
   max_cc: 'Max. CC',
   employee_commissions: 'Employee Commissions',
   min_si: 'Min. SI',
   max_si: 'Max. SI',
   make_model: 'Make & Model',
   creditterm: 'Credit Term',
   invoice: 'Invoice',
   listofvalue: 'List of Value',
   motormodel: 'Motor Model',
   orgunit: 'Org Unit',
   policy: 'Policy',
   productclass: 'Product Class',
   top_product_by_class: 'Top Product By Class',
   role: 'Role',
   receipt: 'Receipt',
   all: 'All',
   user: 'User',
   quote: 'Quote',
   reinsurer: 'Re Insurer',
   policypushsms: 'Policy Push SMS',
   workrequest: 'Work Request',
   tariff: 'Tariff',
   workrequestitem: 'Work Request Item',
   sysjob: 'System Job',
   sysmsg: 'System Message',
   setting: 'Setting',
   selfclaim: 'Self Claim',
   xrate: 'Exchange Rate',
   policycancelrequest: 'Policy Cancel Request',
   payment: 'Payment',
   apikey: 'API Key',
   bill: 'Bill',
   clinic: 'Clinic',
   vendor: 'Vendor',
   msgtpl: 'Message Template',
   ar_query: 'Account Receivable',
   ap_query: 'Account Payable',
   garage: 'Garage',
   category: 'Category',
   category_mapping: 'Category Mapping',
   map_to_category: 'Map to category',
   upload: 'Upload',
   upload_now: 'Upload now',
   message_upload_category:
      'This will upload all categories in your Excel file.',
   message_upload_claim_item:
      'This will upload all claim items in your Excel file.',
   message_upload_clinic_item:
      'This will upload all clinic items in your Excel file.',
   message_upload_risk: 'This will upload all risks in your Excel file.',
   message_upload_cover: 'This will upload all covers in your Excel file.',
   message_upload_smi: 'This will upload all smis in your Excel file.',
   message_upload_condition:
      'This will upload all conditions in your Excel file.',
   message_upload_motor_model:
      'This will upload all motor models in your Excel file.',
   download_category_template: 'Download Category Template',
   download_cover_template: 'Download Cover Template',
   download_person_risk_template: 'Download Risk Template',
   download_condition_template: 'Download Condition Template',
   download_smi_template: 'Download SMI Template',
   download_claim_item_template: 'Download Claim Item Template',
   download_clinic_item_template: 'Download Clinic Item Template',
   download_motor_model_template: 'Download Motor Model Template',
   claim_categories: 'Claim Categories',
   request_amount: 'Request Amount',
   claim_amount: 'Claim Amount',
   categories: 'Categories',
   default_cost: 'Default Cost',
   claim_item: 'Claim Item',
   deductible: 'Deductible',
   deductibles: 'Deductibles',
   show_in_online_purchase: 'Show in online purchase?',
   item: 'Item',
   expected_date: 'Expected Date',
   product: 'Product',
   in_percent: 'in Percent?',
   product_code: 'Product Code',
   product_name: 'Product Name',
   period_type: 'Period Type',
   class: 'Class',
   effective_date: 'Effective Date',
   notes: 'Notes',
   comments: 'Comments',
   '': '',
   rate: 'Rate',
   exchange_rates_table: 'Exchange Rates Table',
   address_and_contacts: 'Address and Contacts',
   deductible_in_percent: 'Deductible (i.e. 5% or 50)',
   deductible_description: 'Deductible Description',
   applied_deductibles: 'Will be applied with these deductibles',
   deductible_line: 'Deductible Line',
   product_smi_cover: 'Product-SMI-Cover',
   default: 'Default',
   due_days: 'Due Days',
   comission: 'Comission',
   clinicitem: 'Clinic Item',
   clinicitemcategory: 'Clinic Item Category',
   clinicitemprice: 'Clinic Item Price',
   commissions: 'Commissions',
   currency_name: 'Currency Name',
   base_currency: 'Base Currency',
   credit_term_name: 'Credit Term Name',
   smi: 'SMI',
   smi_description: 'SMI Description',
   risk: 'Risk',
   condition: 'Condition',
   recipient: 'Recipient',
   charge_code: 'Charge Code',
   charge_name: 'Charge Name',
   claim_admins: 'Claim Admins',
   policy_status: 'Policy Status',
   quotation_status: 'Quotation Status',
   work_request_state: 'Work Request State',
   work_request_priority: 'Work Request Priority',
   claim_status: 'Claim Status',
   clinic_items: 'Clinic Items',
   work_request_admins: 'Work Request Admins',
   product_claim_admins_label:
      'Users who get notified once claim is registered for this product',
   product_workreq_admins_label:
      'Users who get notified once work request is created for this product',
   clinics: 'Clinics',
   treatment_type: 'Treatment Type',
   clinic_invoice_number: 'Clinic Invoice Number',
   account_name: 'Account Name',
   doctor: 'Doctor',
   diagnosis_category: 'Diagnosis Category ',
   diagnosis: 'Diagnosis',
   internal_status: 'Internal Status',
   update_external_status_to: 'Update External Status To',
   notification: 'Notification',
   notif_msg_templates: 'Notification Message Templates',
   notif_msg_template: 'Notification Message Template',
   sms_message_templates: 'Sms Message Templates',
   email_message_templates: 'Email Message Templates',
   insured_email_tpl: 'Insured Email Template',
   insured_sms_tpl: 'Insured SMS Template',
   broke_email_tpl: 'Broker Email Template',
   broke_sms_tpl: 'Broker SMS Template',
   customer_email_tpl: 'Customer Email Template',
   customer_sms_tpl: 'Customer SMS Template',
   pay_account_number: 'Pay Account Number',
   clinic_category: 'Clinic Category',
   special_note: 'Special Note',
   sources: '↓ Sources',
   destination: 'Destination →',
   product_plans: 'Product Plans',
   plan_name: 'Product Plan name',
   copy_plan: 'Copy This Plan',
   setup_plans: 'Setup Plans',
   plan_list: 'Plan List',
   select_a_plan: 'Select a Plan',
   create_plan: 'Create Plan',
   rename_plan: 'Rename Plan',
   delete_plan: 'Delete Plan',

   // Work Request

   to_department: 'To Department',
   priority: 'Priority',
   for: 'For',
   on_behalf_of: 'On behalf of',
   request_item: 'Request Items',
   reason: 'Reason',
   status: 'Status',
   external_status: 'External Status',
   remark: 'Remark',
   request_number: 'Request Number',
   request_date: 'Request Date',
   update_status: 'Update Status',
   //Security

   login_title: 'Login',
   login_button: 'Login',
   user_id: 'User ID',
   user_name: 'User name',
   verify_code: 'Verify Code',
   login_name: 'User name or email or phone number',
   username: 'User name',
   password: 'Password',
   login_notes: 'Welcome to ',
   set_password: 'Set password',
   profile: 'Profile',
   signout: 'Signout',
   account: 'Account',
   account_type: 'Account Type',
   type_detail: 'Type Detail',
   confirm_password: 'Confirm Password',
   role_name: 'Role name',
   key: 'Key',
   forgot_pwd: 'Forgot Password',
   forgot_pwd_input: 'Email or phone number',
   forgot_pwd_msg:
      "Enter the email address or phone number associated with your account and we'll send you a link to reset your password.",
   forgot_pwd_req_success:
      'Successfully submitted, we have emailed your password reset link.',
   claimcategory: 'Claim Category',
   claimitem: 'Claim Item',
   claimpayment: 'Claim Payment',
   clinicclaim: 'Clinic Claim',
   doclib: 'Document Library',
   lovnotif: 'LOV Notification',
   overwritepremum: 'Overwrite Premum',

   //System

   run_type: 'Run Type',
   run_time: 'Run Time',
   run_function: 'Run Function',
   enabled: 'Enabled',
   setting_name: 'Setting Name',
   value: 'Value',
   subject: 'Subject',
   body: 'Body',
   body_message: 'Body Message',
   sender: 'Sender',
   date_time: 'Date Time',
   date_type: 'Date Type',
   tries: 'Tries',
   error: 'Error',
   module_type: 'Module Type',
   fiscalyear: 'Fiscal Year',
   date_from: 'Date From',
   date_to: 'Date To',
   reset: 'Reset',
   successfully_reset: 'Successfully reset',
   hasReset: 'Has reset',

   //Report
   unpaid_claims_insured: 'Unpaid claims to insured',
   view_report: 'View Report',
   statement_of_accounts: 'Statement of Accounts',
   outstanding_reimbursement: 'Outstanding Reimbursement',
   unpaid_claims_clinic: 'Unpaid claims to clinic',
   member: 'Member',
   claim_reports: 'Claim Reports',
   accounting_reports: 'Accounting Reports',
   // Menu
   setup: 'Setup',
   master_data: 'Master Data',
   setup_update_flow: 'Setup Update Flow',
   org_units: 'Org Units',
   org_unit: 'Org Unit',
   partners: 'Partners',
   currencies: 'Currencies',
   product_masters: 'Product Masters',
   risks: 'Risks',
   smis: 'SMIs',
   covers: 'Covers',
   conditions: 'Conditions',
   product_classes: 'Product Classes',
   products: 'Products',
   claim_items: 'Claim Items',
   claimnotif: 'Send Claim Notifications',
   claimnotifmsgtpl: 'Claim Notif Message Templates',
   lov: 'List of Values (LOV)',
   work_request_items: 'Work Request Items',
   locations: 'Locations',
   motors_setup: 'Motors Setup',
   makes: 'Makes',
   models: 'Models',
   special_models: 'Special Models',
   tariffs: 'Tariffs',
   areas: 'Areas',
   underwriting: 'Underwriting',
   work_requests: 'Work Requests',
   quotation_management: 'Quotation Management',
   quotations: 'Quotations',
   policy_push_sms: 'Policy - Push SMS',
   policy_management: 'Policy Management',
   risk_member: 'Risk Member',
   policies: 'Policies',
   renewal_processing: 'Renewal Processing',
   generate_renewal_quote: 'Generate Renewal Quote',
   renewal_status: 'Renewal Status',
   are_you_sure_to_generate_renewal_quote:
      'Are you sure you want to generate renewal quote?',
   claims: 'Claims',
   claim_registered: 'Claim Registered',
   please_select_record: 'Please select a report.',
   payment_requests: 'Payment Requests',
   payment_code: 'Payment Code',
   bank_requirement_info:
      'Payment Method (Bank transfer) required "Bank Name" and "Account Name".',
   claimant: 'Claimant',
   claim_payment_requests: 'Claim Payment Requests',
   claim_payment_request: 'Claim Payment Request',
   accounting: 'Accounting',
   data_setup: 'Data Setup',
   items: 'Items',
   item_categories: 'Item Categories',
   credit_terms: 'Credit Terms',
   receivables: 'Receivables',
   invoices: 'Invoices',
   receipts: 'Receipts',
   receivables_query: 'Receivables Query',
   payables: 'Payables',
   bills: 'Bills',
   payments: 'Payments',
   payment_info: 'Payment Information',
   work_request_list: 'Work Request List',
   payables_query: 'Payables Query',
   security: 'Security',
   api_keys: 'API Keys',
   data_partitions: 'Data Partitions',
   users: 'Users',
   roles: 'Roles',
   system: 'System',
   system_jobs: 'System Jobs',
   settings: 'Settings',
   message_templates: 'Message Templates',
   supported_variables: 'Supported variables',
   messages: 'Messages',
   workflows: 'Workflows',
   data_type: 'Data Type',
   next_number: 'Next Number',
   format: 'Format',
   autonum: 'Auto Numbers',
   limit: 'Limit',
   risk_min_age_warning: 'Risk minimum age warning',
   more_limits: 'More Limits',
   swift_code: 'Swift Code',
   // Header Title
   list_of_value: 'List of Values',
   moto_makes: 'Motor Makes',
   motor_models: 'Motor Models',
   motor_special_models: 'Motor Special Models',
   organisational_units: 'Organisational Units',
   system_settings: 'System Settings',
   my_claims: 'My Claims',
   my_claim: 'My Claim',
   claim_detail: 'Claim Detail',
   my_policies: 'My Policies',
   set_api_key: 'Set API Key Reference Data',
   update_api_key: 'Update Api Key',
   account_payables: 'Account Payables',
   account_receivables: 'Account Receivables',
   claims_receiver_admin: 'Claims Receiver Admin',
   system_setting: 'System Setting',
   register_claim: 'Register Claim',
   claim_register: 'Claim Register',
   claims_register: 'Claims Register',
   reports: 'Reports',
   cl001: 'Report CL001: Claim Registered',
   cl002: 'Report CL002: Statement of Accounts',
   cl003: 'Report CL003: Outstanding Reimbursement',
   cl004: 'Report CL004: Unpaid claims to clinic',
   cl005: 'Report CL005: Unpaid claims to insured',
   cl006: 'Report CL006: Claim SMS',
   exp001: 'Report EXP001: Account Payables & Aging',
   exp002: 'Report EXP002: Expense Summary',
   exp003: 'Report EXP003: Expense Detail',
   exp004: 'Report EXP004: Payment Detail',
   exp005: 'Report EXP005: Purchasing Journal for Tax Department',
   rev001: 'Report REV001: Account Receivables & Aging',
   rev002: 'Report REV002: Revenue Summary',
   rev003: 'Report REV003: Revenue Detail',
   rev004: 'Report REV004: Receipt Detail',
   no_cover: 'No Cover',
   covers_for: 'Covers for {smiName}',
   select_covers: 'Select Covers',
   limit_details_for: 'Limit details for {smiName}',
   approved: 'Approved',
   claim_letter_template: 'Claim Letter Template',
   letter_templates: 'Letter Templates',
   claim_letter_templates: 'Claim Letter Templates',
   template_list: 'Template List',
   refresh: 'Refresh',
   recalculate_premium_success: 'Recalculate Premium Successfully',
   product_plan: 'Product Plan',
   sync_with_master_smis: 'Sync with Master Smis',
   revert: 'Revert',
};
